import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowLeft, Home, Search, Car } from 'lucide-react';

const NotFound: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const popularLinks = [
    { 
      icon: Car,
      title: t('notFound.popularLinks.allVehicles'),
      path: `/${i18n.language}/vehicles`,
      description: t('notFound.popularLinks.allVehiclesDesc')
    },
    {
      icon: Search,
      title: t('notFound.popularLinks.compareBrands'),
      path: `/${i18n.language}/compare-brands`,
      description: t('notFound.popularLinks.compareBrandsDesc')
    },
    {
      icon: Home,
      title: t('notFound.popularLinks.evGuide'),
      path: `/${i18n.language}/electric-car-thailand`,
      description: t('notFound.popularLinks.evGuideDesc')
    }
  ];

  return (
    <div className="min-h-[70vh] flex flex-col items-center justify-center px-4 py-16">
      <div className="text-center mb-8">
        <h1 className="text-6xl font-bold text-gray-900 mb-4">404</h1>
        <p className="text-xl text-gray-600 mb-8">{t('notFound.message')}</p>
        <button
          onClick={() => navigate(-1)}
          className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 mb-4 mx-2"
        >
          <ArrowLeft className="mr-2 h-5 w-5" />
          {t('notFound.goBack')}
        </button>
        <Link
          to={`/${i18n.language}/`}
          className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 mx-2"
        >
          <Home className="mr-2 h-5 w-5" />
          {t('notFound.goHome')}
        </Link>
      </div>

      <div className="max-w-4xl mx-auto">
        <h2 className="text-2xl font-semibold text-gray-900 mb-6 text-center">
          {t('notFound.popularPages')}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {popularLinks.map((link, index) => (
            <Link
              key={index}
              to={link.path}
              className="block p-6 bg-white rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow"
            >
              <div className="flex items-center mb-4">
                <link.icon className="h-6 w-6 text-blue-600 mr-2" />
                <h3 className="font-medium text-gray-900">{link.title}</h3>
              </div>
              <p className="text-gray-600 text-sm">{link.description}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NotFound; 