import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowUp, Car, BookOpen, MessageCircle } from 'lucide-react';

const Footer: React.FC = () => {
  const { t, i18n } = useTranslation();
  const currentYear = new Date().getFullYear();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const footerLinks = [
    {
      title: t('footer.explore'),
      icon: Car,
      links: [
        { text: t('footer.allVehicles'), path: `/${i18n.language}/vehicles` },
        { text: t('footer.compareBrands'), path: `/${i18n.language}/compare-brands` },
        { text: t('footer.evGuide'), path: `/${i18n.language}/electric-car-thailand` },
        { text: t('footer.quiz'), path: `/${i18n.language}/quiz` },
      ]
    },
    {
      title: t('footer.vehicleTypes'),
      icon: Car,
      links: [
        { text: t('footer.suv'), path: `/${i18n.language}/vehicles/type/suv` },
        { text: t('footer.sedan'), path: `/${i18n.language}/vehicles/type/sedan` },
        { text: t('footer.hatchback'), path: `/${i18n.language}/vehicles/type/hatchback` },
        { text: t('footer.sports'), path: `/${i18n.language}/vehicles/type/sports` },
      ]
    },
    {
      title: t('footer.community'),
      icon: MessageCircle,
      links: [
        { text: t('footer.experience'), path: `/${i18n.language}/experience` },
        { text: t('footer.contact'), path: `/${i18n.language}/contact` },
        { text: t('footer.sitemap'), path: '/sitemap.xml' },
      ]
    },
  ];

  return (
    <footer className="bg-gray-900 text-white mt-auto">
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Brand and Description */}
          <div>
            <Link to={`/${i18n.language}/`} className="inline-block mb-4">
              <span className="font-bold text-2xl text-white">RODFAIFA</span>
            </Link>
            <p className="text-gray-400 text-sm mb-4">
              {t('footer.description')}
            </p>
            <p className="text-gray-400 text-sm italic">
              {t('footer.tagline')}
            </p>
          </div>

          {/* Navigation Links */}
          {footerLinks.map((section, index) => (
            <div key={index}>
              <div className="flex items-center gap-2 mb-4">
                <section.icon className="h-5 w-5 text-blue-400" />
                <h3 className="font-semibold text-lg">{section.title}</h3>
              </div>
              <ul className="space-y-2">
                {section.links.map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <Link 
                      to={link.path}
                      className="text-gray-400 hover:text-white transition-colors"
                    >
                      {link.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Bottom Section */}
        <div className="border-t border-gray-800 mt-12 pt-8 flex flex-col md:flex-row justify-between items-center">
          <p className="text-gray-400 text-sm">
            © {currentYear} RODFAIFA. {t('footer.rights')}
          </p>
          <div className="flex items-center gap-4 mt-4 md:mt-0">
            <Link 
              to={`/${i18n.language}/`}
              className="text-gray-400 hover:text-white transition-colors"
            >
              {t('footer.home')}
            </Link>
            <button
              onClick={scrollToTop}
              className="bg-gray-800 p-2 rounded-full hover:bg-gray-700 transition-colors"
              aria-label={t('common.backToTop')}
            >
              <ArrowUp size={20} />
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 