import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { vehicles } from '../data/vehicles';
import VehicleCard from '../components/VehicleCard';
import { SEOHead } from '../components/SEOHead';

export const VehicleTypePage: React.FC = () => {
  const { type } = useParams();
  const { t, i18n } = useTranslation();
  
  const vehiclesOfType = vehicles.filter(v => 
    v.type.toLowerCase() === type?.toLowerCase()
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <SEOHead
        title={t(`types.${type}`)}
        description={t('pages.vehicleType.description', { type: t(`types.${type}`) })}
        canonicalPath={`/vehicles/type/${type}`}
        locale={i18n.language}
      />
      
      <h1 className="text-4xl font-bold mb-6">
        {t('pages.vehicleType.title', { type: t(`types.${type}`) })}
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {vehiclesOfType.map(vehicle => (
          <VehicleCard key={vehicle.id} vehicle={vehicle} />
        ))}
      </div>
    </div>
  );
};

export default VehicleTypePage;