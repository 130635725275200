import './i18n';
import React, { useState, useMemo, Suspense, useCallback, useEffect } from 'react';
import { Menu } from 'lucide-react';
import { vehicles, EVehicle } from './data/vehicles';
import VehicleCard from './components/VehicleCard';
import ComparisonPanel from './components/ComparisonPanel';
import Filters from './components/Filters';
import LanguageSwitch from './components/LanguageSwitch';
import { useTranslation } from 'react-i18next';
import { Routes, Route, Navigate, useLocation, useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { SEOHead } from './components/SEOHead';
import emailjs from '@emailjs/browser';
import { Locale } from './i18n/seoConfig';
import CompareBrands from './pages/CompareBrands';
import Sorry from './pages/Sorry';
import { Layout } from './components/Layout';
import ElectricCarsThailand from './pages/ElectricCarsThailand';
import BrandPage from './pages/BrandPage';
import Footer from './components/Footer';
import NotFound from './pages/NotFound';
import { GoogleAnalytics } from './components/GoogleAnalytics';
import VehicleTypePage from './pages/VehicleTypePage';

// Lazy load components
const Experience = React.lazy(() => import('./pages/Experience'));
const Contact = React.lazy(() => import('./pages/Contact'));
const Quiz = React.lazy(() => import('./pages/Quiz'));
const VehicleDetail = React.lazy(() => import('./pages/VehicleDetail'));
const VehiclesPage = React.lazy(() => import('./pages/VehiclesPage'));

// Initialize EmailJS
emailjs.init("lUupjW33Ww3-N0WR7");

// Home component remains the same
const Home: React.FC<{
  filteredVehicles: EVehicle[];
  comparedVehicles: EVehicle[];
  handleCompare: (vehicle: EVehicle) => void;
  setComparedVehicles: React.Dispatch<React.SetStateAction<EVehicle[]>>;
}> = ({ filteredVehicles, comparedVehicles, handleCompare, setComparedVehicles }) => {
  const { t } = useTranslation();
  
  return (
    <main className="container mx-auto px-4 py-6 lg:py-8 max-w-7xl">
      <div className="mb-8">
        <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-2">
          {t('home.title')}
        </h1>
        <p className="text-sm md:text-base text-gray-600 max-w-2xl">
          {t('home.subtitle')}
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6">
        {filteredVehicles.map((vehicle) => (
          <VehicleCard
            key={vehicle.id}
            vehicle={vehicle}
            onCompare={handleCompare}
            isCompared={comparedVehicles.some(v => v.id === vehicle.id)}
          />
        ))}
      </div>
      {comparedVehicles.length > 0 && (
        <div className="fixed bottom-0 left-0 right-0 z-50 bg-white shadow-lg border-t border-gray-200">
          <ComparisonPanel 
            vehicles={comparedVehicles} 
            onRemove={(id) => {
              setComparedVehicles(prev => prev.filter(v => v.id !== id));
            }} 
          />
        </div>
      )}
    </main>
  );
};

const AppContent: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const locale = i18n.language as Locale;

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [priceRange, setPriceRange] = useState<[number, number]>([0, 10000000]);
  const [batteryRange, setBatteryRange] = useState<[number, number]>([0, 200]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState('price-asc');
  const [comparedVehicles, setComparedVehicles] = useState<EVehicle[]>([]);
  const [selectedMakes, setSelectedMakes] = useState<string[]>([]);

  // Add this effect to handle language changes
  useEffect(() => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const langInPath = pathSegments[0];
    
    if (langInPath !== 'th' && langInPath !== 'en') {
      // No language in path, redirect to default language
      const preferredLanguage = localStorage.getItem('i18nextLng') || 'en';
      const newPath = location.pathname === '/' ? `/${preferredLanguage}` : `/${preferredLanguage}${location.pathname}`;
      navigate(`${newPath}${location.search}`, { replace: true });
    } else if (langInPath !== i18n.language) {
      // Update i18n language to match URL
      i18n.changeLanguage(langInPath);
    }
  }, [location.pathname, i18n, navigate]);

  // Memoize the filtered vehicles calculation
  const filteredVehicles = useMemo(() => {
    const searchLower = searchQuery.toLowerCase().trim();
    return vehicles.filter((vehicle) => {
      const matchesSearch = !searchLower || 
        vehicle.name.toLowerCase().includes(searchLower) ||
        vehicle.brand.toLowerCase().includes(searchLower);
      const matchesPrice =
        vehicle.price >= priceRange[0] && vehicle.price <= priceRange[1];
      const matchesBattery =
        vehicle.batteryCapacity >= batteryRange[0] &&
        vehicle.batteryCapacity <= batteryRange[1];
      const matchesType =
        selectedTypes.length === 0 || selectedTypes.includes(vehicle.type);
      const matchesMake =
        selectedMakes.length === 0 || selectedMakes.includes(vehicle.brand);

      return matchesSearch && matchesPrice && matchesBattery && matchesType && matchesMake;
    }).sort((a, b) => {
      switch (sortBy) {
        case 'price-asc':
          return a.price - b.price;
        case 'price-desc':
          return b.price - a.price;
        case 'range-desc':
          return b.range - a.range;
        case 'acceleration-asc':
          return a.acceleration - b.acceleration;
        default:
          return 0;
      }
    });
  }, [searchQuery, priceRange, batteryRange, selectedTypes, selectedMakes, sortBy]);

  // Remove the memoized HomeWithState and make it a regular component
  const HomeWithState = () => (
    <div>
      <Filters
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        priceRange={priceRange}
        setPriceRange={setPriceRange}
        batteryRange={batteryRange}
        setBatteryRange={setBatteryRange}
        selectedTypes={selectedTypes}
        setSelectedTypes={setSelectedTypes}
        selectedMakes={selectedMakes}
        setSelectedMakes={setSelectedMakes}
        sortBy={sortBy}
        setSortBy={setSortBy}
      />
      <Home
        filteredVehicles={filteredVehicles}
        comparedVehicles={comparedVehicles}
        handleCompare={handleCompare}
        setComparedVehicles={setComparedVehicles}
      />
    </div>
  );

  // Function to get SEO data based on current route
  const getSeoData = () => {
    const searchParams = new URLSearchParams(location.search);
    const searchTerm = searchParams.get('search');
    const pathWithoutLang = location.pathname.replace(/^\/(en|th)/, '');

    // Common paths that should have canonical URLs
    const commonPaths: Record<string, { title: string; description: string }> = {
      '/vehicles/type/sedan': {
        title: t('vehicleTypes.sedan.title'),
        description: t('vehicleTypes.sedan.description'),
      },
      '/vehicles/type/hatchback': {
        title: t('vehicleTypes.hatchback.title'),
        description: t('vehicleTypes.hatchback.description'),
      },
      '/compare-brands/neta-vs-tesla': {
        title: t('comparisons.netaVsTesla.title'),
        description: t('comparisons.netaVsTesla.description'),
      },
      '/compare-brands/aion-vs-ora': {
        title: t('comparisons.aionVsOra.title'),
        description: t('comparisons.aionVsOra.description'),
      },
      '/compare-brands/aion-vs-hyundai': {
        title: t('comparisons.aionVsHyundai.title'),
        description: t('comparisons.aionVsHyundai.description'),
      },
      '/compare-brands/volvo-vs-nissan': {
        title: t('comparisons.volvoVsNissan.title'),
        description: t('comparisons.volvoVsNissan.description'),
      },
      '/compare-brands/hyundai-vs-volvo': {
        title: t('comparisons.hyundaiVsVolvo.title'),
        description: t('comparisons.hyundaiVsVolvo.description'),
      },
    } as const;

    // Check if current path matches any common path
    if (commonPaths[pathWithoutLang]) {
      return commonPaths[pathWithoutLang];
    }

    // Handle vehicle detail pages
    if (pathWithoutLang.startsWith('/vehicles/')) {
      const vehicleId = pathWithoutLang.split('/').pop();
      const vehicle = vehicles.find(v => v.id === vehicleId);
      if (vehicle) {
        return {
          title: t('vehicles.detail.title', { name: vehicle.name }),
          description: t('vehicles.detail.description', { name: vehicle.name, price: vehicle.price }),
        };
      }
    }

    // Default cases
    switch (pathWithoutLang) {
      case '/quiz':
        return {
          title: t('quiz.seo.title'),
          description: t('quiz.seo.description'),
        };
      case '/experience':
        return {
          title: t('experience.seo.title'),
          description: t('experience.seo.description'),
        };
      case '/contact':
        return {
          title: t('contact.seo.title'),
          description: t('contact.seo.description'),
        };
      case '/electric-car-thailand':
        return {
          title: t('electricCarThailand.seo.title'),
          description: t('electricCarThailand.seo.description'),
        };
      default:
        return {
          title: searchTerm 
            ? t('search.results', { searchTerm }) + ' | ' + t('header.title')
            : t('header.title'),
          description: searchTerm
            ? t('search.metaDescription', { searchTerm })
            : t('seo.defaultDescription'),
        };
    }
  };

  const seoData = getSeoData();

  const handleCompare = (vehicle: EVehicle) => {
    setComparedVehicles(prev => {
      // If vehicle is already compared, remove it
      if (prev.some(v => v.id === vehicle.id)) {
        return prev.filter(v => v.id !== vehicle.id);
      }
      // If not yet compared and less than 3 vehicles, add it
      if (prev.length < 3) {
        return [...prev, vehicle];
      }
      return prev;
    });
  };

  // Keep only the BrandRedirectHandler for brand comparisons
  const BrandRedirectHandler = () => {
    const { brands } = useParams();
    const { i18n } = useTranslation();
    const locale = i18n.language as Locale;
    
    const transformedBrands = brands?.replace(/-vs-/g, '/');
    return <Navigate to={`/${locale}/compare-brands/${transformedBrands}`} replace />;
  };

  return (
    <>
      <SEOHead
        title={seoData.title}
        description={seoData.description}
        canonicalPath={location.pathname}
        locale={locale}
      />
      <GoogleAnalytics />
      <div className="min-h-screen bg-gray-50 flex flex-col">
        <header className="bg-white border-b border-gray-100">
          <div className="container mx-auto px-4">
            <div className="flex justify-between items-center h-16">
              <Link to={`/${locale}/`} className="flex items-center gap-2">
                <img 
                  src="/rodfaifa-logo.png" 
                  alt="RodFaifa Logo" 
                  className="h-8 w-auto"
                />
                <span className="font-bold text-xl">RODFAIFA</span>
              </Link>

              <div className="hidden md:flex items-center space-x-8">
                <Link 
                  to={`/${locale}/compare-brands`}
                  className="inline-flex items-center px-4 py-2 bg-blue-50 text-blue-700 rounded-full font-medium hover:bg-blue-100 transition-colors"
                >
                  {t('header.compareEvBrands')}
                </Link>
                <Link to={`/${locale}/quiz`}>{t('header.quiz')}</Link>
                <Link to={`/${locale}/experience`}>{t('header.userExperiences')}</Link>
                <Link to={`/${locale}/contact`} className="text-gray-700 hover:text-gray-900">
                  {t('header.contactUs')}
                </Link>
                <div className="flex items-center gap-4">
                  <span className="text-gray-700">{t('header.vehiclesFound', { count: vehicles.length })}</span>
                  <LanguageSwitch />
                </div>
              </div>

              <button 
                className="md:hidden"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <Menu />
              </button>
            </div>
          </div>

          {mobileMenuOpen && (
            <div className="md:hidden border-t border-gray-100">
              <div className="px-2 pt-2 pb-3 space-y-1">
                <Link 
                  to={`/${locale}/compare-brands`}
                  className="block px-3 py-2 text-base font-medium text-blue-700 bg-blue-50 rounded-md"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  {t('header.compareEvBrands')}
                </Link>
                <Link 
                  to={`/${locale}/quiz`}
                  className="block px-3 py-2 text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 rounded-md"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  {t('header.quiz')}
                </Link>
                <Link 
                  to={`/${locale}/experience`}
                  className="block px-3 py-2 text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 rounded-md"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  {t('header.userExperiences')}
                </Link>
                <Link 
                  to={`/${locale}/contact`}
                  className="block px-3 py-2 text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 rounded-md"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  {t('header.contactUs')}
                </Link>
              </div>
            </div>
          )}
        </header>

        <Suspense fallback={<div>{t('common.loading')}</div>}>
          <Routes>
            {/* Root redirect */}
            <Route 
              path="/" 
              element={<Navigate to={`/${i18n.language}/`} replace />} 
            />
            
            {/* Language prefixed routes */}
            <Route path="/:lang">
              <Route index element={<HomeWithState />} />
              <Route path="compare-brands" element={<CompareBrands />} />
              <Route path="compare-brands/:brands" element={<CompareBrands />} />
              <Route path="experience" element={<Experience />} />
              <Route path="contact" element={<Contact />} />
              <Route path="electric-car-thailand" element={<ElectricCarsThailand />} />
              <Route path="quiz" element={<Quiz />} />
              <Route path="vehicles" element={<VehiclesPage />} />
              <Route path="vehicles/:vehicleId" element={<VehicleDetail />} />
              <Route path="brands" element={<BrandPage />} />
              <Route path="brands/:brandId" element={<BrandPage />} />
              <Route path="/:lang/vehicles/type/:type" element={
                <Suspense fallback={<div>Loading...</div>}>
                  <VehicleTypePage />
                </Suspense>
              } />
              
              {/* Add catch-all route for language paths */}
              <Route path="*" element={<NotFound />} />
            </Route>

            {/* Global 404 catch-all */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>

        <Footer />
      </div>
    </>
  );
};

// Add debug wrapper component
const RouteDebug: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const params = useParams();

  console.log('Route Debug:', {
    pathname: location.pathname,
    params,
    search: location.search
  });

  return <>{children}</>;
};

const App = () => {
  return (
    <HelmetProvider>
      <AppContent />
    </HelmetProvider>
  );
};

export default App;
